import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Categories from './components/Categories';
import JobList from './components/JobList';
import JobSearch from './components/JobSearch';
import { ProfilePage } from './components/profile/ProfilePage';
import Footer from './components/Footer';
import JobForm from './components/jobs/JobForm';
import Dashboard from './components/admin/Dashboard';
import SearchPage from './components/search/SearchPage';
import { useAuth } from './contexts/AuthContext';
import { Job } from './types';

export function App() {
  const { user } = useAuth();
  const [isPostingJob, setIsPostingJob] = useState(false);
  const [isSearchingJobs, setIsSearchingJobs] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handlePostJob = () => {
    setIsPostingJob(true);
    setIsSearchingJobs(false);
    setShowProfile(false);
    setShowAdmin(false);
  };

  const handleFindWork = () => {
    setIsSearchingJobs(true);
    setIsPostingJob(false);
    setShowProfile(false);
    setShowAdmin(false);
  };

  const handleHomeClick = () => {
    setIsPostingJob(false);
    setIsSearchingJobs(false);
    setShowProfile(false);
    setShowAdmin(false);
    setFilteredJobs([]);
    setSearchPerformed(false);
    setSelectedCategory(null);
  };

  const handleProfileClick = () => {
    setShowProfile(true);
    setIsPostingJob(false);
    setIsSearchingJobs(false);
    setShowAdmin(false);
  };

  const handleAdminClick = () => {
    setShowAdmin(true);
    setShowProfile(false);
    setIsPostingJob(false);
    setIsSearchingJobs(false);
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
    setSearchPerformed(true);
    setIsSearchingJobs(true);
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Header 
          onHomeClick={handleHomeClick} 
          onProfileClick={handleProfileClick}
          onAdminClick={handleAdminClick}
        />
        <Routes>
          <Route path="/" element={
            <main>
              {showAdmin ? (
                <Dashboard />
              ) : isPostingJob ? (
                <div className="pt-24 pb-16">
                  <div className="max-w-4xl mx-auto px-4">
                    <h1 className="text-3xl font-bold text-gray-900 mb-8">Post a New Job</h1>
                    <JobForm 
                      onSubmit={async (jobData) => {
                        try {
                          const response = await fetch('/api/jobs', {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(jobData),
                          });
                          if (response.ok) {
                            setIsPostingJob(false);
                            handleHomeClick();
                          }
                        } catch (error) {
                          console.error('Error creating job:', error);
                        }
                      }}
                      onCancel={() => setIsPostingJob(false)}
                    />
                  </div>
                </div>
              ) : showProfile ? (
                <ProfilePage />
              ) : (
                <>
                  <Hero onPostJob={handlePostJob} onFindWork={handleFindWork} />
                  {isSearchingJobs ? (
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                      <JobSearch 
                        onSearch={(jobs, searched) => {
                          setFilteredJobs(jobs);
                          setSearchPerformed(searched);
                        }}
                        selectedCategory={selectedCategory}
                      />
                      <JobList 
                        jobs={filteredJobs} 
                        searchPerformed={searchPerformed}
                        className="mb-16"
                      />
                    </div>
                  ) : (
                    <>
                      <Categories onCategorySelect={handleCategorySelect} />
                      <JobList className="mb-16" />
                    </>
                  )}
                </>
              )}
            </main>
          } />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/jobs/:jobId" element={<JobList />} />
          <Route path="/profile/:userId" element={<ProfilePage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;