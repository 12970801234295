import React, { createContext, useContext, useState } from 'react';

type Language = 'en' | 'fr' | 'darija';

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (key: string) => string;
}

const translations = {
  en: {
    'app.name': 'AjiTkhdm',
    'app.tagline': 'Find Work or Hire Workers in Morocco',
    'app.description': 'Connect with skilled professionals or find your next opportunity',
    'action.postJob': 'Post a Job',
    'action.findWork': 'Find Work',
    'action.search': 'Search for services...',
    'action.login': 'Login',
    'action.logout': 'Sign Out',
    'categories.title': 'Popular Categories',
    'category.development': 'Development',
    'category.design': 'Design',
    'category.marketing': 'Digital Marketing',
    'category.content': 'Content Creation',
    'category.mechanics': 'Mechanics',
    'category.construction': 'Construction',
    'category.carpentry': 'Carpentry',
    'category.tailoring': 'Tailoring',
    'category.cooking': 'Cooking',
    'category.drivers': 'Drivers',
    'category.delivery': 'Delivery',
    'category.cleaning': 'House Cleaning',
    'category.gardening': 'Gardening',
    'category.laundry': 'Laundry',
    'category.childcare': 'Childcare',
    'category.security': 'Security',
    'category.sales': 'Sales',
    'category.training': 'Personal Training',
    'category.tech': 'Tech Support',
    'jobType.remote': 'Remote',
    'jobType.onsite': 'On-site',
    'jobType.hybrid': 'Hybrid',
    'footer.rights': 'All rights reserved.'
  },
  fr: {
    'app.name': 'AjiTkhdm',
    'app.tagline': 'Trouvez du Travail ou Recrutez au Maroc',
    'app.description': 'Connectez-vous avec des professionnels qualifiés ou trouvez votre prochaine opportunité',
    'action.postJob': 'Publier une Offre',
    'action.findWork': 'Trouver du Travail',
    'action.search': 'Rechercher des services...',
    'action.login': 'Connexion',
    'action.logout': 'Déconnexion',
    'categories.title': 'Catégories Populaires',
    'category.development': 'Développement',
    'category.design': 'Design',
    'category.marketing': 'Marketing Digital',
    'category.content': 'Création de Contenu',
    'category.mechanics': 'Mécanique',
    'category.construction': 'Construction',
    'category.carpentry': 'Menuiserie',
    'category.tailoring': 'Couture',
    'category.cooking': 'Cuisine',
    'category.drivers': 'Chauffeurs',
    'category.delivery': 'Livraison',
    'category.cleaning': 'Ménage',
    'category.gardening': 'Jardinage',
    'category.laundry': 'Blanchisserie',
    'category.childcare': 'Garde d\'Enfants',
    'category.security': 'Sécurité',
    'category.sales': 'Vente',
    'category.training': 'Coach Personnel',
    'category.tech': 'Support Technique',
    'jobType.remote': 'À Distance',
    'jobType.onsite': 'Sur Place',
    'jobType.hybrid': 'Hybride',
    'footer.rights': 'Tous droits réservés.'
  },
  darija: {
    'app.name': 'أجي تخدم',
    'app.tagline': 'لقا خدمة ولا خدام فالمغرب',
    'app.description': 'كنربطو الخدامة مع المخدمين بطريقة سهلة ومضمونة',
    'action.postJob': 'خدم شي حد',
    'action.findWork': 'قلب على خدمة',
    'action.search': 'قلب على شي خدمة...',
    'action.login': 'دخل',
    'action.logout': 'خرج',
    'categories.title': 'أنواع الخدمة',
    'category.development': 'برمجة',
    'category.design': 'تصميم',
    'category.marketing': 'تسويق رقمي',
    'category.content': 'صناعة المحتوى',
    'category.mechanics': 'ميكانيك',
    'category.construction': 'بناء',
    'category.carpentry': 'نجارة',
    'category.tailoring': 'خياطة',
    'category.cooking': 'طياب',
    'category.drivers': 'شوفور',
    'category.delivery': 'ليفري',
    'category.cleaning': 'خدامة',
    'category.gardening': 'جرنان',
    'category.laundry': 'صباغة',
    'category.childcare': 'مربية',
    'category.security': 'گارديان',
    'category.sales': 'بياع',
    'category.training': 'كوتش',
    'category.tech': 'تقني',
    'jobType.remote': 'من بعد',
    'jobType.onsite': 'فالبلاصة',
    'jobType.hybrid': 'مختلط',
    'footer.rights': 'جميع الحقوق محفوظة.'
  }
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState<Language>('en');

  const t = (key: string): string => {
    return translations[language][key as keyof typeof translations[typeof language]] || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}