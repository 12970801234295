import React, { useState, useEffect } from 'react';
import { Search, MapPin, Filter } from 'lucide-react';
import { Job } from '../types';
import { getJobs } from '../db';
import { useLanguage } from '../contexts/LanguageContext';

interface JobSearchProps {
  onSearch: (jobs: Job[], searchPerformed: boolean) => void;
  selectedCategory?: string | null;
}

export default function JobSearch({ onSearch, selectedCategory }: JobSearchProps) {
  const { t } = useLanguage();
  const [searchTerm, setSearchTerm] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState(selectedCategory || '');

  useEffect(() => {
    if (selectedCategory) {
      setCategory(selectedCategory);
    }
  }, [selectedCategory]);

  const handleSearch = async () => {
    try {
      const allJobs = await getJobs({ status: 'open' });
      const filteredJobs = allJobs.filter(job => {
        const matchesSearch = !searchTerm || 
          job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.description.toLowerCase().includes(searchTerm.toLowerCase());
        
        const matchesLocation = !location || 
          job.location.toLowerCase().includes(location.toLowerCase());
        
        const matchesCategory = !category || job.category === category;
        
        return matchesSearch && matchesLocation && matchesCategory;
      });
      
      onSearch(filteredJobs, true);
    } catch (error) {
      console.error('Error searching jobs:', error);
    }
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      handleSearch();
    }, 300);

    return () => clearTimeout(delaySearch);
  }, [searchTerm, location, category]);

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100 mb-6">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search jobs by title or description..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500"
            />
          </div>
        </div>
        
        <div className="md:w-64">
          <div className="relative">
            <MapPin className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500"
            />
          </div>
        </div>
        
        <div className="md:w-64">
          <div className="relative">
            <Filter className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 appearance-none bg-white"
            >
              <option value="">All Categories</option>
              <option value="development">Development</option>
              <option value="design">Design</option>
              <option value="marketing">Digital Marketing</option>
              <option value="content">Content Creation</option>
              <option value="mechanics">Mechanics</option>
              <option value="construction">Construction</option>
              <option value="carpentry">Carpentry</option>
              <option value="tailoring">Tailoring</option>
              <option value="cooking">Cooking</option>
              <option value="drivers">Drivers</option>
              <option value="delivery">Delivery</option>
              <option value="cleaning">House Cleaning</option>
              <option value="gardening">Gardening</option>
              <option value="laundry">Laundry</option>
              <option value="childcare">Childcare</option>
              <option value="security">Security</option>
              <option value="sales">Sales</option>
              <option value="training">Personal Training</option>
              <option value="tech">Tech Support</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}