import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch } from '../../hooks/useSearch';
import { SearchResult } from '../../types/search';
import { Search as SearchIcon, Loader } from 'lucide-react';

export default function SearchPage() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') || '';
  const { search, results, isLoading } = useSearch();
  const [displayResults, setDisplayResults] = useState<SearchResult[]>([]);

  useEffect(() => {
    if (query) {
      search(query);
    }
  }, [query, search]);

  useEffect(() => {
    setDisplayResults(results);
  }, [results]);

  if (!query) {
    return (
      <div className="min-h-screen pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Search</h1>
          <p className="text-gray-600">Enter a search term to find jobs, users, or categories.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Search Results for "{query}"
        </h1>

        {isLoading ? (
          <div className="flex items-center justify-center py-12">
            <Loader className="h-8 w-8 animate-spin text-emerald-500" />
          </div>
        ) : displayResults.length === 0 ? (
          <div className="text-center py-12">
            <SearchIcon className="h-12 w-12 mx-auto text-gray-400 mb-4" />
            <h2 className="text-xl font-semibold text-gray-900 mb-2">No results found</h2>
            <p className="text-gray-600">
              Try adjusting your search terms or browse our categories instead.
            </p>
          </div>
        ) : (
          <div className="space-y-6">
            {displayResults.map((result) => (
              <a
                key={result.id}
                href={result.url}
                className="block bg-white rounded-lg shadow-sm border border-gray-100 p-6 hover:shadow-md transition-shadow"
              >
                <div className="flex items-center space-x-4">
                  {result.avatar && (
                    <img
                      src={result.avatar}
                      alt={result.title}
                      className="h-12 w-12 rounded-full"
                    />
                  )}
                  <div>
                    <h2 className="text-lg font-semibold text-gray-900">{result.title}</h2>
                    <p className="text-gray-600">{result.subtitle}</p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}