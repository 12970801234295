import React from 'react';
import { User, Briefcase, Tag } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { SearchResult } from '../../types/search';
import { useLanguage } from '../../contexts/LanguageContext';

interface SearchResultsProps {
  results: SearchResult[];
  isLoading: boolean;
  onClose: () => void;
}

export default function SearchResults({ results, isLoading, onClose }: SearchResultsProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleResultClick = (result: SearchResult) => {
    onClose();
    navigate(result.url);
  };

  if (isLoading) {
    return (
      <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-lg border border-gray-200 p-4">
        <div className="animate-pulse space-y-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="flex items-center space-x-4">
              <div className="h-10 w-10 bg-gray-200 rounded-full" />
              <div className="flex-1 space-y-2">
                <div className="h-4 bg-gray-200 rounded w-3/4" />
                <div className="h-3 bg-gray-200 rounded w-1/2" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-lg border border-gray-200 max-h-[80vh] overflow-y-auto">
      {results.map((result) => (
        <button
          key={result.id}
          onClick={() => handleResultClick(result)}
          className="block w-full p-4 hover:bg-gray-50 transition-colors text-left"
        >
          <div className="flex items-center space-x-4">
            {result.type === 'user' && (
              <>
                {result.avatar ? (
                  <img src={result.avatar} alt={result.title} className="h-10 w-10 rounded-full" />
                ) : (
                  <div className="h-10 w-10 bg-emerald-100 rounded-full flex items-center justify-center">
                    <User className="h-5 w-5 text-emerald-600" />
                  </div>
                )}
              </>
            )}
            {result.type === 'job' && (
              <div className="h-10 w-10 bg-emerald-100 rounded-full flex items-center justify-center">
                <Briefcase className="h-5 w-5 text-emerald-600" />
              </div>
            )}
            {result.type === 'category' && (
              <div className="h-10 w-10 bg-emerald-100 rounded-full flex items-center justify-center">
                <Tag className="h-5 w-5 text-emerald-600" />
              </div>
            )}
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">{result.title}</p>
              <p className="text-sm text-gray-500 truncate">{result.subtitle}</p>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}