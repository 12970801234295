import React, { useState, useRef, useEffect } from 'react';
import { Globe } from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';

export default function LanguageSelector() {
  const { language, setLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getLanguageLabel = (lang: string) => {
    switch (lang) {
      case 'en':
        return 'English';
      case 'fr':
        return 'Français';
      case 'darija':
        return 'الدارجة';
      default:
        return lang;
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-1 text-gray-600 hover:text-emerald-600 px-3 py-2 rounded-md transition-colors"
      >
        <Globe className="h-5 w-5" />
        <span className="text-sm font-medium">
          {getLanguageLabel(language)}
        </span>
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 z-50">
          <button
            onClick={() => {
              setLanguage('en');
              setIsOpen(false);
            }}
            className={`block px-4 py-2 text-sm w-full text-left ${
              language === 'en'
                ? 'bg-emerald-50 text-emerald-700'
                : 'text-gray-700 hover:bg-gray-50'
            }`}
          >
            English
          </button>
          <button
            onClick={() => {
              setLanguage('fr');
              setIsOpen(false);
            }}
            className={`block px-4 py-2 text-sm w-full text-left ${
              language === 'fr'
                ? 'bg-emerald-50 text-emerald-700'
                : 'text-gray-700 hover:bg-gray-50'
            }`}
          >
            Français
          </button>
          <button
            onClick={() => {
              setLanguage('darija');
              setIsOpen(false);
            }}
            className={`block px-4 py-2 text-sm w-full text-left ${
              language === 'darija'
                ? 'bg-emerald-50 text-emerald-700'
                : 'text-gray-700 hover:bg-gray-50'
            }`}
          >
            الدارجة المغربية
          </button>
        </div>
      )}
    </div>
  );
}