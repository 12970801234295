import React from 'react';
import { 
  Code, Paintbrush, PenTool, Camera, Globe2, BookOpen, 
  Megaphone, Languages, Car, Wrench, UtensilsCrossed, 
  Scissors, Home, Warehouse, TreePine, ShoppingBag, Baby,
  Building2, Hammer, Shield, Shirt, Dumbbell, Laptop
} from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';

interface CategoriesProps {
  onCategorySelect: (category: string) => void;
}

export default function Categories({ onCategorySelect }: CategoriesProps) {
  const { t } = useLanguage();

  const categories = [
    { id: 'development', icon: Code, name: t('category.development') },
    { id: 'design', icon: Paintbrush, name: t('category.design') },
    { id: 'marketing', icon: Globe2, name: t('category.marketing') },
    { id: 'content', icon: PenTool, name: t('category.content') },
    { id: 'mechanics', icon: Wrench, name: t('category.mechanics') },
    { id: 'construction', icon: Building2, name: t('category.construction') },
    { id: 'carpentry', icon: Hammer, name: t('category.carpentry') },
    { id: 'tailoring', icon: Scissors, name: t('category.tailoring') },
    { id: 'cooking', icon: UtensilsCrossed, name: t('category.cooking') },
    { id: 'drivers', icon: Car, name: t('category.drivers') },
    { id: 'delivery', icon: Warehouse, name: t('category.delivery') },
    { id: 'cleaning', icon: Home, name: t('category.cleaning') },
    { id: 'gardening', icon: TreePine, name: t('category.gardening') },
    { id: 'laundry', icon: Shirt, name: t('category.laundry') },
    { id: 'childcare', icon: Baby, name: t('category.childcare') },
    { id: 'security', icon: Shield, name: t('category.security') },
    { id: 'sales', icon: ShoppingBag, name: t('category.sales') },
    { id: 'training', icon: Dumbbell, name: t('category.training') },
    { id: 'tech', icon: Laptop, name: t('category.tech') }
  ];

  return (
    <div className="bg-gray-50 py-8 md:py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-6 md:mb-8">
          {t('categories.title')}
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 md:gap-4">
          {categories.map(({ id, icon: Icon, name }) => (
            <button
              key={id}
              onClick={() => onCategorySelect(id)}
              className="bg-white rounded-lg p-4 md:p-6 hover:shadow-md transition-all cursor-pointer border border-gray-100 text-left"
            >
              <Icon className="h-6 w-6 md:h-8 md:w-8 text-emerald-600 mb-2 md:mb-3" />
              <h3 className="font-semibold text-gray-900 text-sm md:text-base">{name}</h3>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}