import { db } from '../schema';
import type { User } from '../../types';

export async function createUser(userData: Omit<User, 'id' | 'createdAt'>): Promise<string> {
  return await db.users.add({
    ...userData,
    createdAt: new Date()
  }) as string;
}

export async function getUser(id: string): Promise<User | undefined> {
  return await db.users.get(id);
}

export async function updateUser(id: string, updates: Partial<User>): Promise<number> {
  return await db.users.update(id, updates);
}

export async function getUserByEmail(email: string): Promise<User | undefined> {
  return await db.users.where('email').equals(email).first();
}