import React, { useState, useRef, useEffect } from 'react';
import { Menu, Bell, User, LogOut, Settings } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';
import AuthModal from './auth/AuthModal';
import LanguageSelector from './LanguageSelector';
import GlobalSearch from './search/GlobalSearch';

interface HeaderProps {
  onHomeClick: () => void;
  onProfileClick: () => void;
  onAdminClick: () => void;
}

export default function Header({ onHomeClick, onProfileClick, onAdminClick }: HeaderProps) {
  const { user, logout } = useAuth();
  const { t } = useLanguage();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onHomeClick();
  };

  const isAdmin = user?.email === 'admin@ajitkhdm.com';

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsProfileMenuOpen(false);
        setIsMobileMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <header className="bg-white shadow-sm fixed w-full top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <a
              href="/"
              onClick={handleLogoClick}
              className="text-xl md:text-2xl font-bold text-emerald-600 hover:text-emerald-700 transition-colors"
            >
              {t('app.name')}
            </a>
          </div>
          
          <div className="hidden md:block flex-1 max-w-2xl mx-8">
            <GlobalSearch />
          </div>

          <div className="flex items-center space-x-2 md:space-x-4">
            <LanguageSelector />
            
            {user ? (
              <>
                <button className="text-gray-600 hover:text-emerald-600 hidden md:block">
                  <Bell className="h-6 w-6" />
                </button>
                <div className="relative" ref={menuRef}>
                  <button 
                    onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                    className="flex items-center space-x-2 bg-emerald-50 hover:bg-emerald-100 px-3 md:px-4 py-2 rounded-lg"
                  >
                    <User className="h-5 w-5 text-emerald-600" />
                    <span className="text-emerald-600 font-medium hidden md:block">{user.name}</span>
                  </button>
                  
                  {isProfileMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5">
                      <button
                        onClick={() => {
                          onProfileClick();
                          setIsProfileMenuOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <User className="h-4 w-4 mr-2" />
                        Profile
                      </button>
                      {isAdmin && (
                        <button
                          onClick={() => {
                            onAdminClick();
                            setIsProfileMenuOpen(false);
                          }}
                          className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          <Settings className="h-4 w-4 mr-2" />
                          Admin Dashboard
                        </button>
                      )}
                      <button
                        onClick={() => {
                          logout();
                          setIsProfileMenuOpen(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <LogOut className="h-4 w-4 mr-2" />
                        {t('action.logout')}
                      </button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <button
                onClick={() => setIsAuthModalOpen(true)}
                className="flex items-center space-x-2 bg-emerald-50 hover:bg-emerald-100 px-3 md:px-4 py-2 rounded-lg"
              >
                <User className="h-5 w-5 text-emerald-600" />
                <span className="text-emerald-600 font-medium hidden md:block">{t('action.login')}</span>
              </button>
            )}

            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="md:hidden text-gray-600 hover:text-emerald-600"
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden border-t border-gray-100 py-4">
            <div className="mb-4">
              <GlobalSearch />
            </div>
            {user && (
              <div className="space-y-2">
                <button className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-lg">
                  <Bell className="h-4 w-4 mr-2" />
                  Notifications
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
      />
    </header>
  );
}