import { useState, useCallback } from 'react';
import { db } from '../db';
import { SearchResult } from '../types/search';
import { useLanguage } from '../contexts/LanguageContext';

export function useSearch() {
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useLanguage();

  const search = useCallback(async (query: string) => {
    setIsLoading(true);
    try {
      const normalizedQuery = query.toLowerCase();

      // Search jobs
      const jobs = await db.jobs
        .filter(job => 
          job.title.toLowerCase().includes(normalizedQuery) ||
          job.description.toLowerCase().includes(normalizedQuery) ||
          job.category.toLowerCase().includes(normalizedQuery)
        )
        .toArray();

      // Search users
      const users = await db.users
        .filter(user => 
          user.name.toLowerCase().includes(normalizedQuery) ||
          (user.jobTitle && user.jobTitle.toLowerCase().includes(normalizedQuery))
        )
        .toArray();

      // Search categories (from predefined list)
      const categories = ['development', 'design', 'marketing', 'content', 'mechanics']
        .filter(category => category.includes(normalizedQuery))
        .map(category => ({
          id: category,
          type: 'category' as const,
          title: category.charAt(0).toUpperCase() + category.slice(1),
          subtitle: `View all ${category} jobs`,
          url: `/jobs?category=${category}`
        }));

      // Combine and format results
      const formattedResults: SearchResult[] = [
        ...jobs.map(job => ({
          id: job.id!,
          type: 'job' as const,
          title: job.title,
          subtitle: `${job.location} • ${job.salary.amount} ${job.salary.currency}/${job.salary.type}`,
          url: `/jobs/${job.id}`
        })),
        ...users.map(user => ({
          id: user.id!,
          type: 'user' as const,
          title: user.name,
          subtitle: user.jobTitle || 'Member',
          avatar: user.avatar,
          url: `/profile/${user.id}`
        })),
        ...categories
      ];

      setResults(formattedResults);
    } catch (error) {
      console.error('Search error:', error);
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  }, [language]);

  return { results, isLoading, search };
}