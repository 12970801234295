import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

export default function Footer() {
  const { t } = useLanguage();

  return (
    <footer className="bg-gray-900 text-gray-300 py-8 md:py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center space-y-4">
          <h3 className="text-white text-lg font-semibold mb-2">{t('app.name')}</h3>
          <p className="text-sm mb-4">Connect with skilled professionals or find your next opportunity</p>
          <p className="text-sm mb-4">Our goal is to connect independent professionals with people needing them and to grow a strong community of consumers in Morocco.</p>
          <p className="text-sm">For any further information or any request, feel free to contact us at ahmedrissik@gmail.com</p>
        </div>
        <div className="border-t border-gray-800 mt-8 pt-8 text-sm text-center">
          © 2024 {t('app.name')}. {t('footer.rights')}
        </div>
      </div>
    </footer>
  );
}