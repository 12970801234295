import React, { useState, useEffect } from 'react';
import { Clock, MapPin, DollarSign, MessageCircle, SearchX, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Job } from '../types';
import { getJobs } from '../db';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';

interface JobListProps {
  jobs?: Job[];
  searchPerformed?: boolean;
  className?: string;
}

export default function JobList({ jobs: propJobs, searchPerformed = false, className = '' }: JobListProps) {
  const { user: currentUser } = useAuth();
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadJobs = async () => {
      setIsLoading(true);
      try {
        if (propJobs) {
          setJobs(propJobs);
        } else {
          const loadedJobs = await getJobs({ status: 'open' });
          setJobs(loadedJobs);
        }
      } catch (error) {
        console.error('Error loading jobs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadJobs();
  }, [propJobs]);

  const handleContactEmployer = (employerId: string) => {
    if (!currentUser) {
      alert('Please sign in to contact the employer');
      return;
    }
    navigate(`/profile/${employerId}`);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  if (searchPerformed && jobs.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <SearchX className="h-16 w-16 mb-4 text-gray-500" />
        <h3 className="text-xl font-semibold mb-2">No jobs found</h3>
        <p>Try adjusting your search criteria</p>
      </div>
    );
  }

  return (
    <div className={`max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 space-y-6 pt-8 ${className}`}>
      {jobs.map((job) => (
        <div key={job.id} className="bg-white rounded-lg shadow-sm border border-gray-100 p-6 hover:shadow-md transition-shadow">
          <div className="flex justify-between items-start">
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{job.title}</h3>
              
              <div className="flex flex-wrap gap-4 text-sm text-gray-500 mb-4">
                <div className="flex items-center">
                  <MapPin className="h-4 w-4 mr-1" />
                  {job.location} • {t(`jobType.${job.type}`)}
                </div>
                <div className="flex items-center">
                  <DollarSign className="h-4 w-4 mr-1" />
                  {job.salary?.amount} {job.salary?.currency}/{job.salary?.type}
                </div>
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-1" />
                  {new Date(job.date).toLocaleDateString()}
                </div>
              </div>

              <p className="text-gray-600 mb-4">{job.description}</p>

              <div className="flex gap-4">
                <button
                  onClick={() => handleContactEmployer(job.clientId)}
                  className="flex items-center text-emerald-600 hover:text-emerald-700"
                >
                  <MessageCircle className="h-4 w-4 mr-1" />
                  Contact Employer
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}