import React from 'react';
import { Camera, Upload } from 'lucide-react';
import { User } from '../../types';

interface ProfileHeaderProps {
  user: User | null;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  onImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  canEdit: boolean;
}

export function ProfileHeader({ user, isEditing, setIsEditing, onImageUpload, canEdit }: ProfileHeaderProps) {
  return (
    <div className="relative h-48 bg-gradient-to-r from-emerald-500 to-teal-600 rounded-t-lg">
      <div className="absolute -bottom-16 left-8 flex items-end space-x-6">
        <div className="relative">
          <div className="h-32 w-32 rounded-full border-4 border-white bg-white overflow-hidden">
            {user?.avatar ? (
              <img
                src={user.avatar}
                alt={user.name}
                className="h-full w-full object-cover"
              />
            ) : (
              <div className="h-full w-full bg-emerald-50 flex items-center justify-center">
                <Camera className="h-12 w-12 text-emerald-300" />
              </div>
            )}
          </div>
          {canEdit && (
            <label className="absolute bottom-0 right-0 h-8 w-8 bg-emerald-600 rounded-full flex items-center justify-center cursor-pointer hover:bg-emerald-700 transition-colors">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={onImageUpload}
              />
              <Upload className="h-4 w-4 text-white" />
            </label>
          )}
        </div>
      </div>
      {canEdit && (
        <div className="absolute right-8 bottom-8">
          <button
            onClick={() => setIsEditing(!isEditing)}
            className="bg-white text-emerald-600 px-4 py-2 rounded-lg font-medium hover:bg-emerald-50 transition-colors"
          >
            {isEditing ? 'Cancel' : 'Edit Profile'}
          </button>
        </div>
      )}
    </div>
  );
}