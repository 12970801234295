import React from 'react';
import { ArrowRight, Briefcase, Users } from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';

interface HeroProps {
  onPostJob: () => void;
  onFindWork: () => void;
}

export default function Hero({ onPostJob, onFindWork }: HeroProps) {
  const { t } = useLanguage();

  return (
    <div className="relative bg-gradient-to-r from-emerald-500 to-teal-600">
      <div className="relative min-h-[600px] md:min-h-[700px] flex items-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center w-full pt-32 md:pt-36 pb-16">
          <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-white mb-8 px-4">
            {t('app.tagline')}
          </h1>
          <p className="text-lg md:text-xl lg:text-2xl text-white/90 mb-12 max-w-3xl mx-auto px-4">
            Connect with skilled professionals or find your next opportunity
          </p>
          
          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-16 px-4">
            <button
              onClick={onPostJob}
              className="bg-white text-emerald-600 px-6 py-3 md:py-4 rounded-lg font-semibold hover:bg-emerald-50 flex items-center justify-center text-base md:text-lg shadow-lg hover:shadow-xl transition-all"
            >
              <Briefcase className="mr-2 h-5 w-5 md:h-6 md:w-6" />
              {t('action.postJob')}
              <ArrowRight className="ml-2 h-4 w-4 md:h-5 md:w-5" />
            </button>
            <button
              onClick={onFindWork}
              className="bg-emerald-600 text-white px-6 py-3 md:py-4 rounded-lg font-semibold hover:bg-emerald-700 flex items-center justify-center text-base md:text-lg shadow-lg hover:shadow-xl transition-all border border-emerald-400"
            >
              <Users className="mr-2 h-5 w-5 md:h-6 md:w-6" />
              {t('action.findWork')}
              <ArrowRight className="ml-2 h-4 w-4 md:h-5 md:w-5" />
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 max-w-5xl mx-auto px-4 mb-8">
            <div className="bg-white/10 backdrop-blur-md rounded-lg p-4 md:p-6 text-white">
              <h3 className="text-lg md:text-xl font-semibold mb-2">The first C2C community of services in Morocco</h3>
              <p className="text-sm md:text-base">Connecting independent professionals with people needing them</p>
            </div>
            <div className="bg-white/10 backdrop-blur-md rounded-lg p-4 md:p-6 text-white">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Verified Workers</h3>
              <p className="text-sm md:text-base">Skilled professionals with verified profiles</p>
            </div>
            <div className="bg-white/10 backdrop-blur-md rounded-lg p-4 md:p-6 text-white">
              <h3 className="text-lg md:text-xl font-semibold mb-2">24/7 Support</h3>
              <p className="text-sm md:text-base">Dedicated support team to help you succeed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}