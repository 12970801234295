import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { ProfileHeader } from './ProfileHeader';
import { ProfileForm } from './ProfileForm';
import { ProfileView } from './ProfileView';
import { ProfileFormData } from './types';
import { getUser } from '../../db/operations/users';

export function ProfilePage() {
  const { userId } = useParams();
  const { user: currentUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [profileUser, setProfileUser] = useState(currentUser);
  const [formData, setFormData] = useState<ProfileFormData>({
    name: '',
    jobTitle: '',
    bio: '',
    hourlyRate: '',
    skills: '',
    languages: '',
    education: '',
    experience: '',
  });

  useEffect(() => {
    const loadUser = async () => {
      if (userId && userId !== currentUser?.id) {
        const user = await getUser(userId);
        setProfileUser(user || null);
        setIsEditing(false);
      } else {
        setProfileUser(currentUser);
      }
    };

    loadUser();
  }, [userId, currentUser]);

  useEffect(() => {
    if (profileUser) {
      setFormData({
        name: profileUser.name || '',
        jobTitle: profileUser.jobTitle || '',
        bio: profileUser.bio || '',
        hourlyRate: profileUser.hourlyRate?.toString() || '',
        skills: profileUser.skills?.join(', ') || '',
        languages: profileUser.languages?.join(', ') || '',
        education: profileUser.education || '',
        experience: profileUser.experience || '',
      });
    }
  }, [profileUser]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // TODO: Implement image upload
      console.log('Uploading image:', file);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // TODO: Implement profile update
    setIsEditing(false);
  };

  const canEdit = currentUser?.id === (userId || currentUser?.id);

  return (
    <div className="max-w-4xl mx-auto px-4 py-8 mt-16">
      <div className="bg-white rounded-lg shadow-sm border border-gray-100">
        <ProfileHeader
          user={profileUser}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onImageUpload={handleImageUpload}
          canEdit={canEdit}
        />
        
        <div className="pt-20 px-8 pb-8">
          {isEditing && canEdit ? (
            <ProfileForm
              formData={formData}
              setFormData={setFormData}
              onSubmit={handleSubmit}
              setIsEditing={setIsEditing}
            />
          ) : (
            <ProfileView formData={formData} user={profileUser} />
          )}
        </div>
      </div>
    </div>
  );
}